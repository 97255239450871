import React, { useState } from "react";
import { FaCheckDouble, FaRegCopy } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "../Styles/complainId.scss";
function ComplainId({ complainId, setSearchId, data }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(complainId).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  const navigate = useNavigate();

  function track() {
    setSearchId(complainId);
    navigate("/complainStatus");
  }

  return (
    <>
      {data.map((data) => {
        return (
          <>
            <div className="parent complainId">
              <div className="complainId-cont cont">
                <span>
                  <FaCheckDouble />
                </span>
                <h2 className="h2-copy">
                  {data.copy_complain_id} : <strong>{complainId}</strong>{" "}
                  <button
                    onClick={handleCopy}
                    className="copy-button"
                    aria-label="Copy Complain ID"
                    style={{
                      padding: "10px",
                      border: "1px solid #580b0b",
                      borderRadius: "5px",
                    }}
                  >
                    {copied ? (
                      <>
                        <FaCheckDouble
                          style={{ color: "#580b0b", fontSize: "20px" }}
                        />{" "}
                        {data.copy_copied}
                      </>
                    ) : (
                      <>
                        <FaRegCopy
                          style={{ color: "#580b0b", fontSize: "20px" }}
                        />{" "}
                        <h5 style={{ color: "#580b0b" }}>{data.copy_click_to_copy}</h5>
                      </>
                    )}
                  </button>
                </h2>
                <p>
                 {data.copy_complain_id_msg}
                </p>
                <button className="btn" onClick={track}>
                  {data.copy_track_progress_cta}
                </button>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default ComplainId;
