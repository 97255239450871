import React, { useState } from "react";
import "../Styles/ComplainStatus.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import app from "../Firebase";

function ComplainStatus({ data, setSearchId, searchId }) {
  const [complaintData, setComplaintData] = useState({
    progress: "pending",
    complainID: searchId,
    updated: "",
    feedback: "",
    complaint_status: "",
  });

  const [isValidComplaint, setIsValidComplaint] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const db = getFirestore(app);
  const complaintsCollection = collection(db, "complains");
  const navigate = useNavigate();

  const fetchData = async () => {
    if (!searchId) return; // Exit if no complaint ID is provided

    try {
      const userQuery = query(
        complaintsCollection,
        where("id", "==", searchId) // Use searchId directly here
      );
      const querySnapshot = await getDocs(userQuery);
      const complaints =
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) || [];

      if (complaints.length > 0) {
        const complaint = complaints[0];
        setComplaintData((prevData) => ({
          ...prevData,
          feedback: complaint.feedback || "",
          progress: complaint.complaint_status || "pending",
          complainID: complaint.id || prevData.complainID,
        }));
        setTableData(complaints);
        setIsValidComplaint(true);
        setErrorMessage(""); // Clear any previous error
      } else {
        setIsValidComplaint(false);
        setTableData([]);
        setErrorMessage("No data found for the provided complaint ID.");
      }
    } catch (error) {
      console.error("Error fetching data from Firestore: ", error);
      setErrorMessage(
        "An error occurred while fetching data. Please try again."
      );
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchData(); // Fetch data only on form submit
    navigate("/complainStatus"); // Navigate after the search
  };

  return (
    <>
      {data.map((data, index) => {
        return (
          <div className="track-parent1 parent">
              <div key={index} className="track-container cont">
              <div className="query-container-right container">
                <h3 className="track-hed">{data.track_heading}</h3>
                <form
                  className="complaint-number-form"
                  onSubmit={handleSearchSubmit}
                >
                  <input
                    type="text"
                    placeholder={data.complaint_no}
                    onChange={(e) => setSearchId(e.target.value.toUpperCase())}
                  />
                  <button type="submit" className="btn">
                    {data.track_btn}
                  </button>
                </form>
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
              </div>
            </div>

            {/* Conditional Rendering */}
            {isValidComplaint && (
              <div className="parent complainStatus">
                <div className="cont complainStatus-cont">
                  <h1>{data.complain_status}</h1>
                  <h4>
                    <strong>{searchId}</strong>
                  </h4>

                  <div
                    className={`progress 
            ${
              complaintData.progress === "Pending"
                ? "zero"
                : complaintData.progress === "In Progress"
                ? "fifty"
                : complaintData.progress === "Completed"
                ? "hundred"
                : complaintData.progress === "Rejected"
                ? "cancelled"
                : ""
            }`}
                  >
                    <div
                      className={
                        complaintData.progress === "Pending"
                          ? "dot dot1 active"
                          : "dot dot1"
                      }
                    ></div>
                    <div
                      className={
                        complaintData.progress === "In Progress"
                          ? "dot dot2 active"
                          : "dot dot2"
                      }
                    ></div>
                    <div
                      className={
                        complaintData.progress === "Completed" ||
                        complaintData.progress === "Rejected"
                          ? "dot dot3 active"
                          : "dot dot3"
                      }
                    ></div>
                  </div>

                  {/* Displaying complaint data */}
                  {tableData.map((complaint) => (
                    <div
                      className="complaint-data-container"
                      key={complaint.index}
                    >
                      <div className="complaint-data-box">
                        <div className="first-row">
                          <h3 className="complaint-data">
                            {data.village} : {" "}
                            <span className="d-span">
                              {complaint.vname || "N/A"}
                            </span>
                          </h3>
                          <h3 className="complaint-data">
                            {data.taluka} : {" "}
                            <span className="d-span">
                              {complaint.taluka || "N/A"}
                            </span>
                          </h3>
                        </div>

                        <div className="second-row">
                          <h3 className="complaint-feedback">{data.feedback} : </h3>
                          <p className="feedback-p">
                            {complaint.feedback || "No feedback provided."}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
}

export default ComplainStatus;
