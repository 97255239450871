import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  getFirestore,
  serverTimestamp,
} from "firebase/firestore";
import "../Styles/query.scss";
import app from "../Firebase";

function Query(props) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fname: "",
    vname: "",
    address: "",
    taluka: "",
    district:"",
    pnumber: "",
    message: "",
    work_status: "Pending",
    feedback: "No Feedback ",
  });

  const db = getFirestore(app);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Reference to the custom variable document
      const customVarRef = doc(db, "customVariables", "customVar001");

      // Get the current complainId from the document
      const customVarSnap = await getDoc(customVarRef);

      if (!customVarSnap.exists()) {
        throw new Error("Custom variable document does not exist.");
      }

     
      let currentId = customVarSnap.data().complainId; // Get the current complainId
      const nextId = currentId + 1; // Increment the ID
      const customComplainId = `SPN-T3-${nextId}`; // Generate the full custom ID

      // Reference to the new document in the "complains" collection
      const taskDataRef = doc(collection(db, "complains"), customComplainId);

      // Save the complain document with the custom ID
      await setDoc(taskDataRef, {
        id: customComplainId,
        ...formData,
        timestamp: serverTimestamp(),
      });

      // Update the complainId field in the custom variable document
      await updateDoc(customVarRef, { complainId: nextId });

      // Reset the form
      setFormData({
        fname: "",
        vname: "",
        address: "",
        taluka: "",
        pnumber: "",
        message: "",
        complaint_status: "Pending",
      });

      props.setComplainId(customComplainId);
      navigate("/complainId");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            {/* ---container-right----- */}
            <div className="track-parent parent">
              <div className="track-container cont">
                <div className="query-container-right container">
                  <h2>{data.track_heading}</h2>
                  <form className="complaint-number-form">
                    <button
                      type="submit"
                      className="btn"
                      onClick={(e) => navigate("/complainStatus")}
                    >
                      {data.track_btn}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div key={data.title} className="query-parent parent">
              <div className="query-container cont">
                <div className="query-container-box">
                  {/* ---container-left----- */}
                  <div className="query-container-left">
                    <div className="query-form-box">
                      <h2 className="form-title">{data.title}</h2>
                      {/* .query  */}
                      <form className="query-form" onSubmit={handleSubmit}>
                        <div className="form-row">
                          <input
                            type="text"
                            placeholder={data.fname}
                            name="fname"
                            value={formData.fname}
                            onChange={handleChange}
                            required
                          />
                          <input
                            type="tel"
                            placeholder={data.phone}
                             name="pnumber"
                            value={formData.pnumber}
                            required
                            maxLength="10"
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only numbers and limit to 10 characters
                              if (/^\d*$/.test(value)) {
                                setFormData({
                                  ...formData,
                                  pnumber: value,
                                });
                              }
                            }}
                          />
                        </div>

                        <div className="form-row">
                          <input
                            type="text"
                            placeholder={data.address}
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                          />
                          <input
                            type="text"
                            placeholder={data.vname}
                            name="vname"
                            value={formData.vname}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-row">
                          
                          <input
                            type="text"
                            placeholder={data.taluka}
                            name="taluka"
                            value={formData.taluka}
                            onChange={handleChange}
                            required
                          />
                              <input
                            type="text"
                            placeholder={data.district}
                            name="district"
                            value={formData.district}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="form-row">
                          <textarea
                            name="message"
                            rows="6"
                            placeholder={data.message}
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                        <button type="submit" className="btn btn-new">
                          {data.button}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}

export default Query;
